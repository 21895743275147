/* You can add global styles to this file, and also import other style files */
@import "src/assets/extraStyles/theme.css";
@import "node_modules/primeng/resources/primeng.css";
@import "node_modules/primeflex/primeflex.css";
@import "node_modules/primeicons/primeicons.css";
@font-face {
  font-family: Helvetica;
  src: url(/assets/fonts/Helvetica.ttf);
  /* Otros estilos de fuente, como weight y style, si están disponibles en la fuente */
}

body {
  font-family: 'Helvetica', Arial;
  padding:0px;
  overflow-x: hidden;
}


